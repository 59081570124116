module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Quaranteen University","short_name":"Quaranteen University","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"R:\\Documents\\GitHub\\quaranteens\\www/src/images/seal.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-161219423-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-styled-components-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"light":{"actions":{"error":"#f44336","info":"#201e20","primary":"#2f8dff","success":"#5f9951","warning":"#dd8819"},"palette":{"mainBrand":"#2f8dff","lightAccent":"#8b8e95","lightShades":"#fff","darkAccent":"#004599","darkShades":"#201e20"},"global":{"bg":"#fff","color":"#201e20","link":"#2f8dff","linkHover":"#004599","muted":"#6c757d","logoTint":"rgba(0,0,0,0)","panel":"#e1e1e1","subpanel":"#fff"}},"dark":{"actions":{"error":"#f44336","info":"#201e20","primary":"#2f8dff","success":"#5f9951","warning":"#dd8819"},"palette":{"mainBrand":"#004599"},"global":{"bg":"#201e20","color":"#fff","link":"#2f8dff","linkHover":"#8b8e95","muted":"#96a2ad","logoTint":"rgba(0,0,0,0.4)","panel":"#272727","subpanel":"#2b2b2b"}}},
    }]
